import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";
import { isFound } from "../utilities/isFound";
import { removeRelatedArchivedMessages } from "./archivedMessages";

const initialState = {
  archivedConversations: [],
  archivedConversationsStatus: "idle",
  error: null,
  statusClientArchivedConversation: "idle",
  clientArchivedConversations: [],
  clientArchivedConversationStored: {},
  totalPagesForArchivedConversations: null,
  pageCounter: 1,
  statusUnarchiveConversation: "idle",
};

export const getArchivedConversations = createAsyncThunk(
  "archivedConversation/getArchivedConversations",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `websites/${body?.websiteID}/archives/conversations?limit=15&page=${body?.page}`
      );
      data = response.data.data.docs;

      if (response.status === 200) {
        return {
          data,
          totalPages: response?.data?.data?.meta?.totalPages,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getOneArchivedConversation = createAsyncThunk(
  "archivedConversation/getOneArchivedConversation",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body?.websiteID}/archives/conversations/${body?.conversationID}`
      );
      data = response.data.data?.conversation;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const unarchiveConversation = createAsyncThunk(
  "archivedConversation/unarchiveConversation",
  async (body) => {
    let data;
    try {
      const response = await axios.patch(
        `websites/${body?.websiteID}/archives/conversations/${body?.conversationID}/unarchive`
      );
      data = response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "archivedConversation",
  initialState,
  reducers: {
    storeArchivedConversation: (state, action) => {
      state.clientArchivedConversations.forEach((item) =>
        item._id === action.payload
          ? (state.clientArchivedConversationStored = item)
          : null
      );
    },
    incrementPageForArchivedConversations: (state) => {
      state.pageCounter = state.pageCounter + 1;
    },
    deleteArchivedConversationSuccess: (state, action) => {
      state.archivedConversations = state.archivedConversations.filter(
        (conversation) => conversation._id !== action.payload
      );
      state.clientArchivedConversations =
        state.clientArchivedConversations.filter(
          (conversation) => conversation._id !== action.payload
        );
      state.clientArchivedConversationStored = {};
    },
  },
  extraReducers: {
    [getArchivedConversations.pending]: (state, action) => {
      action.meta?.arg?.page === 1
        ? (state.archivedConversationsStatus = "loading")
        : (state.archivedConversationsStatus = "pending");
    },
    [getArchivedConversations.fulfilled]: (state, action) => {
      state.archivedConversationsStatus = "succeeded";
      state.totalPagesForArchivedConversations = action.payload.totalPages;
      action?.meta?.arg?.page === 1
        ? (() => {
            state.archivedConversations = action.payload.data;
          })()
        : (state.archivedConversations = state.archivedConversations.concat(
            action.payload.data
          ));
    },
    [getArchivedConversations.rejected]: (state) => {
      state.archivedConversationsStatus = "fail";
    },
    [getOneArchivedConversation.pending]: (state) => {
      state.statusClientArchivedConversation = "loading";
    },
    [getOneArchivedConversation.fulfilled]: (state, action) => {
      state.statusClientArchivedConversation = "succeeded";
      !isFound(state.clientArchivedConversations, action.payload?._id) &&
        (state.clientArchivedConversations = [
          ...state.clientArchivedConversations,
          action.payload,
        ]);
      state.clientArchivedConversationStored = action.payload;
    },
    [getOneArchivedConversation.rejected]: (state) => {
      state.statusClientArchivedConversation = "fail";
    },
    [unarchiveConversation.pending]: (state) => {
      state.statusUnarchiveConversation = "loading";
    },
    [unarchiveConversation.fulfilled]: (state) => {
      state.statusUnarchiveConversation = "success";
    },
    [unarchiveConversation.rejected]: (state) => {
      state.statusUnarchiveConversation = "failed";
    },
  },
});
export const {
  storeArchivedConversation,
  incrementPageForArchivedConversations,
  deleteArchivedConversationSuccess,
} = slice.actions;
export const reducer = slice.reducer;
export default slice;

export const deleteArchivedConversation = (data) => async (dispatch) => {
  try {
    dispatch(deleteArchivedConversationSuccess(data));
    dispatch(removeRelatedArchivedMessages(data));
  } catch (error) {
    return console.error(error.message);
  }
};
